import NotifyConfig from '../conf/notify'
import router from '../router'
import { notify } from "@kyvg/vue3-notification";


const AxiosErrorHandler = function(error) {
    notify({ ...NotifyConfig, ...{
            title: 'Error',
            text: Object.entries(error.response?.data?.message).map(([key, value]) => {
                return `${key}: ${value}`
            }).join(', ') ?? error.response.status
        }
    });

    if (error.response.status === 401) {
        router.push({ name: 'login' })
    }
}

export default AxiosErrorHandler;